import { useEffect, useState } from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

export const ScrollToTheTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="fixed bottom-4 right-4">
      <button
        type="button"
        onClick={() => {
          scrollToTop();
        }}
        className={`${
          isVisible ? "opacity-100" : "opacity-0"
        } inline-flex items-center p-3 rounded-full shadow-sm text-white bg-laziz-orange transition-opacity hover:bg-emerald-700 focus:outline-none`}
      >
        <BsFillArrowUpCircleFill className="h-10 w-10" aria-hidden="true" />
      </button>
    </div>
  );
};
