import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { BsX } from "react-icons/bs";
import Tag from "../Tag";

export default function MenuItem(props) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const imageUrl = `url('${props.image}')`;

  let optionDisplay = props.options ? (
    props.optionsList.map((optionName, index) => {
      return <Tag name={optionName} key={index} />;
    })
  ) : (
    <Fragment />
  );

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className="h-24 w-24 truncate rounded-xl bg-black/50 bg-gradient-to-r from-slate-600 to-slate-900 bg-cover bg-center  px-4 py-2 text-xs font-medium text-white backdrop-blur hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 lg:h-36 lg:w-36 lg:text-base"
        style={{
          backgroundImage: imageUrl,
          backgroundBlendMode: "overlay",
        }}
      >
        {props.name}
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-xl bg-off-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="absolute top-0 right-0">
                    <button
                      type="button"
                      className="rounded-md p-2.5 text-rose-500"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close Menu Item</span>
                      <BsX className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>

                  <img
                    className="m-6 rounded-lg"
                    src={props.image}
                    alt={props.name}
                  />

                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {props.name}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{props.description}</p>
                  </div>
                  {props.options ? (
                    <Dialog.Title
                      as="h3"
                      className="mt-2 text-lg font-medium leading-6 text-gray-900"
                    >
                      Options:
                    </Dialog.Title>
                  ) : (
                    <Fragment />
                  )}
                  <div className="grid grid-cols-3 gap-3 lg:flex  lg:flex-row">
                    {optionDisplay}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
