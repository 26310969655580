import { ImLocation2, ImPhone } from "react-icons/im";
import { SiJusteat, SiUbereats, SiDoordash } from "react-icons/si";
import HoursModal from "../Modal/hours";
import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import {Icon} from "leaflet"
function MapPlaceholder() {
  return (
    <p>
      Laziz on a map{' '}
      <noscript>You need to enable JavaScript to see this map.</noscript>
    </p>
  )
}
export default function ContactCard(props) {
  const {
    location,
    streetAddress,
    cityProv,
    postalCode,
    geoLocation,
    phone,
    hours,
    deliveryLinks,
  } = props;
  const { skip, uber, doorDash } = deliveryLinks;
  let address = `${streetAddress}, ${cityProv}, ${postalCode}`;
  const customIcon = new Icon ({
    iconUrl: require("../../images/favicon.png"),
    iconSize: [38,45]
  })

  return (
    <div className="w-full justify-center rounded-xl bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-orange-400 via-orange-600 to-orange-800 p-9 ">
      <div className="flex flex-col items-center justify-center">
        <h1 className="title_text text-shadow drop-shadow-[0_1.8px_1.8px_rgba(0,0,0,0.9)]">
          {location}
        </h1>
        <div className="flex flex-col justify-center">
          <div className="mt-9 mb-9 flex flex-row justify-center gap-4">
            <div className="h-24 w-24 rounded-lg bg-off-white p-3 hover:scale-110 lg:h-36 lg:w-36">
              <a
                className="text-orange-500"
                href={skip}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiJusteat className="h-full w-full " />
              </a>
            </div>
            <div className="h-24 w-24 rounded-lg bg-green-400 p-3 hover:scale-110 lg:h-36 lg:w-36">
              <a
                className="text-black"
                href={uber}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiUbereats className="h-full w-full " />
              </a>
            </div>
            <div className="h-24 w-24 rounded-lg bg-black p-3 hover:scale-110 lg:h-36 lg:w-36">
              <a
                className="text-red-500"
                href={doorDash}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiDoordash className="h-full w-full " />
              </a>
            </div>
          </div>
          <div>
          <MapContainer
              className="  h-80  z-10"
              center={geoLocation}
              zoom={17.5}
              scrollWheelZoom={false}
              placeholder={<MapPlaceholder/>}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={geoLocation} icon={customIcon}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Marker>
            </MapContainer>
          </div>
          
          <p className="paragraph_text text-center">
            {" "}
            <ImLocation2 className=" inline-block " /> {address}{" "}
          </p>
          <p className="paragraph_text text-center">
            {" "}
            <ImPhone className=" inline-block " /> {phone}{" "}
          </p>
          <HoursModal hours={hours} />
        </div>
      </div>
    </div>
  );
}
