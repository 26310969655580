import HomeSection from "./sections/homeSection";
import AboutSection from "./sections/aboutSection";
import MenuSection from "./sections/menuSection";
import ContactSection from "./sections/contactSection";
import FAQSection from "./sections/faqSection";

import { ScrollToTheTop } from "./components/Buttons/scrollToTheTop";

export default function App() {
  return (
    <>
      <div className="flex h-full w-full flex-col overflow-hidden">
        <HomeSection />
        <AboutSection />
        <MenuSection />
        <ContactSection />
        <FAQSection />
      </div>
      <ScrollToTheTop />
    </>
  );
}
