import { SiFacebook, SiInstagram, SiTwitter } from "react-icons/si";

function Icon(props) {
  const { children } = props;
  return (
    <span
      className="
            mr-4
            flex
            h-6
            cursor-pointer
            transition-colors
          hover:text-gray-300"
    >
      {children}
    </span>
  );
}
export default function Footer() {
  return (
    <div className="h-50 absolute inset-x-0 bottom-0 flex w-full flex-col items-center bg-laziz-orange p-2 text-white">
      <div className="flex h-full w-full max-w-6xl flex-col justify-center lg:max-w-7xl">
        <div className="flex justify-center">
          <Icon>
            <a
              href="https://www.facebook.com/lazizbroastmixgrill"
              target="_blank"
              rel="noreferrer"
            >
              <SiFacebook size={30} />
            </a>
          </Icon>
          <Icon>
            <a
              href="https://www.instagram.com/lazizbroastmixgrill"
              target="_blank"
              rel="noreferrer"
            >
              <SiInstagram size={30} />
            </a>
          </Icon>
          <Icon>
            <a
              href="https://twitter.com/lazizbroast"
              target="_blank"
              rel="noreferrer"
            >
              <SiTwitter size={30} />
            </a>
          </Icon>
        </div>
        <div className="h-15 bottom-0 mt-10 flex w-full flex-wrap items-center justify-between border-t border-t-laziz-white border-opacity-50 lg:h-14">
          <span className="font-size[x-small] w-full text-center text-laziz-white ">
            © 2013 Laziz Broast & Mix Grill
          </span>
        </div>
      </div>
    </div>
  );
}
