import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { BsX, BsClockFill } from "react-icons/bs";
import HoursTable from "../Table/hours";

export default function HoursModal(props) {
  const { hours } = props;
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className="mt-5 truncate rounded-xl bg-laziz-green p-3 text-base font-medium text-black backdrop-blur focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        View Hours
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex w-full max-w-lg transform flex-col items-center justify-center overflow-hidden rounded-xl bg-off-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="absolute top-0 right-0">
                    <button
                      type="button"
                      className="rounded-md p-2.5 text-rose-500"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close Menu Item</span>
                      <BsX className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>

                  <BsClockFill className="h-20 w-20 " />
                  <HoursTable className="h-full w-full" hours={hours} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
