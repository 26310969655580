export const GridGraphic = () => {
  return (
    <div className="dark:bg-gray-800 py-6 lg:py-12 w-full md:h-full h-auto rounded-bl-xl rounded-tl-xl ">
    <div className="mx-auto px-4 md:px-8">

        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
          
            <div
                className="group relative flex h-40 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg sm:h-80">
                <img src="./images/grid/image1.png" loading="lazy" alt="Broast" className="absolute inset-0 h-full w-full object-cover object-center transition group-hover:scale-105" />

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg text-right">Broast</span>
            </div>
    
            <div
                className="group relative flex h-40 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 sm:h-80">
                <img src="./images/grid/image2.png" loading="lazy" alt="Souvlaki" className="absolute inset-0 h-full w-full object-cover object-center transition group-hover:scale-105" />

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg text-right">Souvlaki</span>
            </div>

            <div
                className="group relative flex h-40 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 sm:h-80">
                <img src="./images/grid/image4.png" loading="lazy" alt="Tandoori Half" className="absolute inset-0 h-full w-full  object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Tandoori Half</span>
            </div>
      
            <div
                className="group relative flex h-40 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg sm:h-80">
                <img src="./images/grid/image3.png" loading="lazy" alt="Piri Piri Half" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Piri Piri Half</span>
            </div>
            <div
                className="group relative flex h-40  items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg sm:h-80">
                <img src="./images/grid/image5.png" loading="lazy" alt="Collage" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>
            </div>
            <div
                className="group relative flex h-40 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 sm:h-80">
                <img src="./images/grid/image6.png" loading="lazy" alt="Tandoori" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Tandoori Kabob</span>
            </div>
 
        </div>
    </div>
</div>
  );
};
