export const Menu = [
  {
    itemName: "Tandoori Kabob",
    description:
      "Chicken Breast pieces marinated in our special fresh tandoori spices and served with basmati rice, salad & afghan naan",
    image: "./images/tandooric.svg",
    options: false,
  },
  {
    itemName: "Chicken Tikka Kabob",
    description:
      "Chicken Breast pieces marinated in our special fresh spices and served with basmati rice, salad & afghan naan",
    image: "./images/ctikka.svg",
    options: false,
  },
  {
    itemName: "Chicken Souvlaki Kabob",
    description:
      "Chicken Breast pieces marinated in our special fresh greek spices and served with basmati rice, greek salad & greek grilled potatoes",
    image: "./images/csouvlaki.svg",
    options: false,
  },
  {
    itemName: "Chaplee Kabob",
    description:
      "2 round patties of ground beef marinated in our special fresh spices and served with basmati rice, salad & afghan naan",
    image: "./images/chaplee.svg",
    options: false,
  },
  {
    itemName: "Beef Tikka Kabob",
    description:
      "Tender beef pieces marinated in our special fresh spices and served with basmati rice, salad & afghan naan",
    image: "./images/beeftikka.svg",
    options: false,
  },
  {
    itemName: "Beef Shami Kabob",
    description:
      "2 skewers fresh ground beef marinated in our special fresh spices and served with basmati rice, salad & afghan naan",
    image: "./images/beefshami.svg",
    options: false,
  },
  {
    itemName: "Piri piri Half Chicken",
    description:
      "Tender half chicken marinated in our special fresh Piri piri spices and served with basmati rice & coleslaw salad",
    image: "./images/ppc.svg",
    options: false,
  },
  {
    itemName: "Tandoori Half Chicken",
    description:
      "Tender half chicken marinated in our special fresh Tandoori spices and served with basmati rice, salad & afghan naan",
    image: "./images/tandoorihalf.svg",
    options: false,
  },
  {
    itemName: "Family Kabob Platter",
    description:
      "4 skewers of Beef Shami Kabob skewers, 2 Chicken Kabob skewers, 1 Beef Tikka Kabob skewer served with basmati rice, salad & afghan naan (serves roughly 3 - 5 people)",
    image: "./images/mixkabob.svg",
    options: false,
  },
  {
    itemName: "Broast Chicken Combo",
    description:
      "Crispy pressure cooked fried chicken, served with fresh cut fries, coleslaw & a pop drink.",
    image: "./images/broastc2.svg",
    options: true,
    optionsList: ["2 piece", "4 piece", "6 piece"],
  },
  {
    itemName: "Family Broast Chicken Combo",
    description:
      "Crispy pressure cooked fried chicken, served with fresh cut fries, coleslaw & 4 pop drinks.",
    image: "./images/broastc.svg",
    options: true,
    optionsList: [
      "9 piece",
      "12 piece",
      "16 piece",
      "20 piece",
      "Chicken only",
    ],
  },
  {
    itemName: "Fajita with Rice",
    description:
      "Strips of tender chicken or ribeye steak marinated in our fresh spices, mixed with veggies(onions, bell peppers etc) and served on top of rice",
    image: "./images/cfajita.svg",
    options: false,
  },
  {
    itemName: "Sandwiches",
    description:
      "Strips of tender chicken or ribeye steak marinated in our fresh spices, mixed with veggies(onions, bell peppers etc). Served in either sub-bread or tortilla alongside a choice of salad or fries",
    image: "./images/steaksandwich.svg",
    options: true,
    optionsList: ["Fries", "Salad", "Only"],
  },
  {
    itemName: "Beef Burger",
    description: "Beef patty, dressed with lettuce, tomato, onions and pickles",
    image: "./images/beefburger.jpg",
    options: true,
    optionsList: ["Only", "Fries"],
  },
  {
    itemName: "Broast Burger",
    description:
      "Tender broast chicken patty, dressed with lettuce, tomato, onions and pickles",
    image: "./images/brstburger.svg",
    options: true,
    optionsList: ["Only", "Fries"],
  },
  {
    itemName: "Piri piri Burger",
    description:
      "Tender piri piri chicken patty, dressed with mayo, lettuce, tomato, onions and pickles",
    image: "./images/ppburger.svg",
    options: true,
    optionsList: ["Only", "Fries"],
  },
  {
    itemName: "Greek Salad",
    description:
      "Crisp romaine lettuce with cucumbers, tomatos, onions, feta cheese and olives",
    image: "./images/greeksalad.svg",
    options: false,
  },
];
