import { Element } from "react-scroll";
import { Disclosure } from "@headlessui/react";
import { BsChevronDoubleUp } from "react-icons/bs";
import Footer from "../components/Footer";

export default function FAQSection() {
  return (
    <Element
      className="relative flex h-screen w-full flex-col items-center pt-1 pb-1 xl:pt-2 xl:pb-2"
      name="faq"
    >
      <div className="w-full px-4 pt-16">
        <div className=" mx-auto flex  w-full max-w-md flex-col gap-4 rounded-2xl bg-off-white p-4 lg:gap-8 lg:p-20">
          <h1 className="text-center  text-3xl xl:text-3xl 2xl:text-5xl mt-6 lg:mt-0 2xl:mt-6 2xl:mb-6 text-black font-bold" >FAQ</h1>
          <Disclosure className="mb-5">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-200 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-300 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75">
                  <span className="uppercase">Looking to franchise?</span>
                  <BsChevronDoubleUp
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-orange-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  Please get in touch with us by sending an email to
                  info-laziz@gmail.com
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-200 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-300 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75">
                  <span className="uppercase">Is the food حلال?</span>
                  <BsChevronDoubleUp
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-orange-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  All of our food is Certified Halal. Chicken is HMA(Halal
                  Monitering Association) Certified. Beef is sourced locally by
                  Billal Farms-Halal.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-200 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-300 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75">
                  <span className="uppercase">Do we offer Delivery?</span>
                  <BsChevronDoubleUp
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-orange-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  We have teamed up with third party application services such
                  as DoorDash, SkipTheDishes and UberEats to provide variety of
                  options and ensure that our food arrives with quality/care.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-orange-200 px-4 py-2 text-left text-sm font-medium text-orange-900 hover:bg-orange-300 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75">
                  <span className="uppercase">
                    Want to place an order ahead of time?
                  </span>
                  <BsChevronDoubleUp
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-orange-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  {`You can call us ahead of time to place your order and avoid
                  longer wait times. Please call the respective line, East Location: 613-746-6111`}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <Footer className="relative" />
    </Element>
  );
}
