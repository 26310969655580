import NavBar from "../components/Nav/NavBar";
import BackgroundImage from "../images/HPage.webp";
import { Element } from "react-scroll";

export default function HomeSection() {
  return (
    <Element name="home" className="relative flex h-screen w-full flex-col ">
      <div
        className="flex h-screen w-full flex-col bg-black/50 bg-gradient-to-r from-slate-600 to-slate-900 bg-cover bg-center backdrop-blur"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: "bottom 10% left",
          backgroundBlendMode: "overlay",
        }}
      >
        <NavBar />
        <div className="relative h-screen px-6 lg:px-8">
          <div className="mx-auto max-w-3xl pt-20 pb-32 sm:pt-48 sm:pb-40">
            <h1 className="floating_header">
              Simply <span className="text-laziz-orange">Delicious Food.</span>
            </h1>
            <h3 className="floating_sub_header mt-3">
              Prepared with care and passion
            </h3>
          </div>
        </div>
      </div>
    </Element>
  );
}
