import { Element } from "react-scroll";
import { AboutGraphic } from "../components/Graphics/aboutGraphic";

export default function AboutSection() {
  return (
    <Element
      className="flex h-screen w-full flex-col items-center pt-1 pb-1 align-middle xl:pt-2 xl:pb-2"
      name="about"
    >
      <div className="relative flex h-full w-full flex-col items-center align-middle lg:flex-row ">
        <div className="flex flex-col gap-6 p-4">
          <h1 className="floating_header text-left">About</h1>
          <p className="paragraph_text">
            Laziz Broast & Mix grill strives to serve simply delicious food with
            care and passion in Ottawa, Canada. We offer Broast Chicken,
            Souvlaki, Kabobs, Peri Peri Chicken and much more. We take great
            pride in using fresh and quality ingredients. Staying true to
            authentic recipes from around the world. At Laziz, we seek to
            satisfy your tastebuds. Just try us…the taste of our delicious food
            and warm hospitality will bring you back.
          </p>

          <p className="font-size[x-small] mt-8 pl-4 pr-4 text-left text-laziz-white ">
            <em>All chicken and beef menu items are certified </em>
            <b className="underline decoration-double underline-offset-8">
              حلال{" "}
            </b>
          </p>
        </div>
        <AboutGraphic />
      </div>
    </Element>
  );
}
