import { Element } from "react-scroll";
import ContactCard from "../components/Cards/ContactCard";
import { storeInfo } from "../state/storeInfo";

export default function ContactSection() {
  return (
    <Element
      className="relative flex h-screen w-screen flex-col items-center justify-center pt-1 pb-1 xl:pt-2 xl:pb-2"
      name="contact"
    >
      <div className=" mt-28 justify-items-center gap-x-4 gap-y-4 p-4 lg:grid-cols-2">
        {storeInfo.map((store) => {
          return (
            <ContactCard
              key={store.id}
              location={store.location}
              streetAddress={store.streetAddress}
              cityProv={store.cityProv}
              postalCode={store.postalCode}
              geoLocation={store.geoLocation}
              phone={store.phone}
              hours={store.hours}
              deliveryLinks={store.deliveryLinks}
            />
          );
        })}
      </div>
    </Element>
  );
}
