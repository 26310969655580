import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Pages } from "../../state/pages.js";
import { Link } from "react-scroll";
import { BsJustify, BsXCircleFill } from "react-icons/bs";
export default function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="px-6 pt-6 lg:px-8">
      <div>
        <nav
          className="flex h-9 items-center justify-between"
          aria-label="Global"
        >
          <div
            className="flex duration-300 lg:min-w-0 lg:flex-1"
            aria-label="Global"
          >
            <Link to="home" smooth="easeInOutQuad" className="-m-1.5 p-1.5">
              <img
                className="h-10 hover:scale-110"
                src="./Laziz_Logo.svg"
                alt=""
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <BsJustify className="h-10 w-10" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12">
            {Pages.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                smooth="easeInOutQuad"
                className="text-shadow text-xl font-bold text-laziz-white decoration-emerald-400 hover:underline hover:decoration-8"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel
            focus="true"
            className="fixed inset-0 z-10 overflow-y-auto bg-laziz-green px-6 py-6 lg:hidden"
          >
            <div className="flex h-9 items-center justify-between">
              <div className="flex">
                <Link to="home" smooth="easeInQuad" className="-m-1.5 p-1.5">
                  <span className="sr-only">Laziz</span>
                  <img className="h-10" src="./Laziz_Logo.svg" alt="" />
                </Link>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-rose-500"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <BsXCircleFill className="h-8 w-8" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {Pages.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      smooth="easeInOutQuad"
                      onClick={() => setMobileMenuOpen(false)}
                      className="text-shadow -mx-3  block rounded-lg py-2 px-3 text-xl font-semibold leading-7 text-laziz-white decoration-orange-400 focus:underline"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </div>
  );
}
