import { Element } from "react-scroll";
import MenuItem from "../components/Modal/menuItem";
import { Menu } from "../state/menu";

export default function MenuSection() {
  return (
    <Element
      className="relative flex h-screen w-screen flex-col items-center justify-center pt-1 pb-1 xl:pt-2 xl:pb-2"
      name="menu"
    >
      <div className="grid w-screen grid-cols-3  justify-items-center gap-x-8 gap-y-4 lg:grid-cols-5">
        {Menu.map((menuItems, index) => (
          <MenuItem
            key={index}
            name={menuItems.itemName}
            image={menuItems.image}
            description={menuItems.description}
            options={menuItems.options}
            optionsList={menuItems.optionsList}
          />
        ))}
      </div>
    </Element>
  );
}
