export const storeInfo = [
  {
    id: "east_location_store",
    location: "EAST LOCATION",
    streetAddress: "1900 Innes Rd",
    cityProv: "Ottawa, ON",
    postalCode: "K2B-8K9",
    phone: "613-746-6111",
    geoLocation:[ 45.418215,-75.595799],
    hours: [
      {
        day: "Monday",
        time: "11:30am - 9:00pm",
      },
      {
        day: "Tuesday",
        time: "11:30am - 9:00pm",
      },
      {
        day: "Wednesday",
        time: "11:30am - 9:00pm",
      },
      {
        day: "Thursday",
        time: "11:30am - 10:00pm",
      },
      {
        day: "Friday",
        time: "11:30am - 10:30pm",
      },
      {
        day: "Saturday",
        time: "2:00pm - 10:00pm",
      },
      {
        day: "Sunday",
        time: "2:00pm - 9:00pm",
      },
    ],
    deliveryLinks: {
      skip: "https://www.skipthedishes.com/laziz-broast-mix-grill",
      doorDash:
        "https://www.doordash.com/en-CA/store/laziz-broast-&-mix-grill-ottawa-299201/",
      uber: "https://www.ubereats.com/ca/store/laziz-broast-%26-mix-grill/JDRUuTQ4Tymi_KoxS4uRRA?diningMode=DELIVERY&ps=1",
    },
  }
];
