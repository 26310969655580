export default function HoursTable(props) {
  const { hours } = props;
  return (
    <div>
      <table className="mt-3 table-auto p-2 ">
        <thead className=" uppercase">
          <tr>
            <th className="lg:p-2">Day</th>
            <th className="lg:p-2">Hours</th>
          </tr>
        </thead>
        <tbody>
          {hours.map((time, index) => {
            return (
              <tr key={index}>
                <td className="lg:p-2 ">{time.day}</td>
                <td className="lg:p-2">{time.time}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
