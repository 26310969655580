import { useMediaQuery } from "react-responsive";
import { GridGraphic } from "./gridGraphic";
import { SpiceGraphic } from "./spiceGraphic";
export const AboutGraphic = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })
  let Graphic = isTabletOrMobile ? SpiceGraphic : GridGraphic;
  return (
    <Graphic/>
  );
};
